<template>
  <b-card>
      <b-row>
        <b-col>
          <h3>Publicação do Imóvel</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h3>Dados do Imóvel</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Projeto/Obra" label-for="projeto-imovel">
            <b-form-select id="projeto-imovel" v-model="projetoObra" :options="projetos" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="4"
        >
          <b-form-group label="Nome do Imóvel" label-for="nome-imovel">
            <b-form-input id="nome-imovel" placeholder="Nome do Imóvel" />
          </b-form-group>
        </b-col>
        <b-col
          sm="2"
        >
          <b-form-group label="CEP" label-for="cep-imovel">
            <b-form-input id="cep-imovel" type="number" placeholder="99.999-99" />
          </b-form-group>
        </b-col>
        <b-col
          sm="4"
        >
          <b-form-group label="Rua" label-for="rua-imovel">
            <b-form-input id="rua-imovel" placeholder="Rua x" />
          </b-form-group>
        </b-col>
        <b-col
          sm="2"
        >
          <b-form-group label="Número" label-for="numero-imovel">
            <b-form-input id="numero-imovel" type="number" placeholder="999" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="4"
        >
          <b-form-group label="Complemento" label-for="comp-imovel">
            <b-form-input id="comp-imovel" placeholder="Complemento do endereço" />
          </b-form-group>
        </b-col>
        <b-col
          sm="2"
        >
          <b-form-group label="Bairro" label-for="bairro-imovel">
            <b-form-input id="bairro-imovel" placeholder="Bairro do imóvel" />
          </b-form-group>
        </b-col>
        <b-col
          sm="4"
        >
          <b-form-group label="Cidade" label-for="cidade-imovel">
            <b-form-input id="cidade-imovel" placeholder="Nome da Cidade" />
          </b-form-group>
        </b-col>
        <b-col
          sm="2"
        >
          <b-form-group label="Estado" label-for="estado-imovel">
            <b-form-input id="estado-imovel" placeholder="SP" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="2"
        >
          <b-form-group label="Número da Unidade" label-for="unidade-imovel">
            <b-form-input id="unidade-imovel" placeholder="999" />
          </b-form-group>
        </b-col>
        <b-col
          sm="2"
        >
          <b-form-group label="Area Privada m²" label-for="area-imovel">
            <b-form-input id="area-imovel" type="number" placeholder="90" />
          </b-form-group>
        </b-col>
        <b-col
          sm="2"
        >
          <b-form-group label="Tipo do Imóvel" label-for="tipo-imovel">
            <b-form-input id="tipo-imovel" placeholder="Apartamento,Loft,Cobertura,etc" />
          </b-form-group>
        </b-col>
        <b-col
          sm="2"
        >
          <b-form-group label="Qtd Suítes" label-for="suites-imovel">
            <b-form-input id="suites-imovel" type="number" placeholder="2" />
          </b-form-group>
        </b-col>
        <b-col
          sm="2"
        >
          <b-form-group label="Qtd Dormitórios" label-for="dormitorios-imovel">
            <b-form-input id="dormitorios-imovel" type="number" placeholder="3" />
          </b-form-group>
        </b-col>
        <b-col
          sm="2"
        >
          <b-form-group label="Qtd Banheiros" label-for="banheiros-imovel">
            <b-form-input id="banheiros-imovel" type="number" placeholder="2" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="2"
        >
          <b-form-group label="Qtd Lavabo" label-for="lavabo-imovel">
            <b-form-input id="lavabo-imovel" type="number" placeholder="1" />
          </b-form-group>
        </b-col>
        <b-col
          sm="2"
        >
          <b-form-group label="N° Andares" label-for="andares-imovel">
            <b-form-input id="andares-imovel" type="number" placeholder="3" />
          </b-form-group>
        </b-col>
        <b-col
          sm="2"
        >
          <b-form-group label="Vagas de Garagem" label-for="garagem-imovel">
            <b-form-input id="garagem-imovel" type="number" placeholder="3" />
          </b-form-group>
        </b-col>
        <b-col
          sm="2"
        >
          <b-form-group label="Área de Serviço" label-for="servico-imovel">
            <b-form-select id="servico-imovel" v-model="service" :options="options" />
          </b-form-group>
        </b-col>
        <b-col
          sm="2"
        >
          <b-form-group label="Closet" label-for="closet-imovel">
            <b-form-select id="closet-imovel" v-model="closet" :options="options" />
          </b-form-group>
        </b-col>
        <b-col
          sm="2"
        >
          <b-form-group label="Piscina Privativa" label-for="piscina-imovel">
            <b-form-select id="piscina-imovel" v-model="piscina" :options="options" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="2"
        >
          <b-form-group label="Quarto de Empregada" label-for="empregada-imovel">
            <b-form-select id="empregada-imovel" v-model="quartoEmp" :options="options" />
          </b-form-group>
        </b-col>
        <b-col
          sm="2"
        >
          <b-form-group label="churrasqueira" label-for="churrasqueira-imovel">
            <b-form-select id="churrasqueira-imovel" v-model="churrasqueira" :options="options" />
          </b-form-group>
        </b-col>
        <b-col
          sm="3"
        >
          <b-form-group label="Área de Máquinas" label-for="maquinas-imovel">
            <b-form-select id="maquinas-imovel" v-model="maquinas" :options="options" />
          </b-form-group>
        </b-col>
        <b-col
          sm="3"
        >
          <b-form-group
            label="Remuneração Esperada"
            label-for="remuneracao-imovel"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <span>R$</span>
              </b-input-group-prepend>
              <b-form-input
                id="remuneracao-imovel"
                placeholder="10.000.000,00"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          sm="2"
        >
          <b-form-group label="Total de Cotas" label-for="cotas-imovel">
            <b-form-input id="cotas-imovel" type="number" placeholder="2500" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="2"
        >
          <b-form-group
            label="Valor da Cota"
            label-for="cota-imovel"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <span>R$</span>
              </b-input-group-prepend>
              <b-form-input
                id="cota-imovel"
                placeholder="4.000,00"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          sm="4"
        >
          <b-form-group label="Prazo máximo de venda de 50% das cotas" label-for="prazo-imovel">
            <b-form-input id="prazo-imovel" type="date" placeholder="3" />
          </b-form-group>
        </b-col>
        <b-col
          sm="2"
        >
          <b-form-group label="Data de Publicação" label-for="publi-imovel">
            <b-form-input id="publi-imovel" type="date" placeholder="3" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="pt-2">
        <b-col>
          <form-wizard-dados-imovel />
        </b-col>
      </b-row>
  </b-card>
</template>

<script>

import {
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BCard,
  BFormSelect,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import FormWizardDadosImovel from './FormDadosImovel.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BCard,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    FormWizardDadosImovel,
  },
  data() {
    return {
      service: null,
      closet: null,
      piscina: null,
      quartoEmp: null,
      churrasqueira: null,
      maquinas: null,
      projetoObra: null,
      options: [
        { value: 's', text: 'Sim' },
        { value: 'n', text: 'Não' },
      ],
      status: [
        { value: 'rejeitado', text: 'Rejeitado' },
        { value: 'pendente', text: 'Pendente de Informação' },
      ],
      projetos: [
        { value: 'proj01', text: 'Nome do Projeto/Obra 01' },
        { value: 'proj02', text: 'Nome do Projeto/Obra 02' },
        { value: 'proj03', text: 'Nome do Projeto/Obra 03' },
        { value: 'proj04', text: 'Nome do Projeto/Obra 04' },
        { value: 'proj05', text: 'Nome do Projeto/Obra 05' },
      ],
    }
  },
}
</script>

<style>

</style>
